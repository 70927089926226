
/* deep css added to video-js generated DOM elements */
@media only screen and (max-width: 600px) {
    .video-js {
        height: 100vw;
    }

    .video-js .vjs-tech {
        height: 100vw;
    }

    #vjs_video_3 {
        height: 100vw;
    }

    .c-player,
    .c-player__screen {
        height: 100vw;
    }

}